import { makeQuery } from 'api/api';
import { ApiEndpoints } from 'api/endpoints';
import { FilterParameter, QueryParameter, SortParameter } from 'api/types';
import { generateTimeInterval } from 'components/table/instances/alarmListViewTable/utils';
import { DateTime, Interval } from 'luxon';
import { AlarmReport, AlarmReportItem, AlarmReportSchema } from 'model/alarmReport/schema';
import { getDaysOfWeek } from 'shared/utils/getDaysOfWeek';

interface Props {
    storeId: number;
    dateFrom: string;
    dateTo: string;
    duration: number;
    sort?: SortParameter;
    filter?: FilterParameter;
    query?: QueryParameter;
}

/**
 * append data to alarm if date startOn and/or end_on inside the given interval
 *
 * @returns
 */
export const getAlarmReportListView = async ({ storeId, dateFrom, dateTo, duration, sort, filter }: Props): Promise<AlarmReport> => {
    const response = await makeQuery(ApiEndpoints.getAlarmReport(storeId, dateFrom, dateTo), true, sort, filter);
    const parsedResponse = AlarmReportSchema.parse(response);

    // Remapped the given api for alarm horizontal bars/chart
    const mappedResponse: AlarmReport['data']['alarms'] = [];
    for (const alarm of parsedResponse.data.alarms) {
        const { assetName, zone, event, comment, startOn, endOn, priority } = alarm;
        const isExist = mappedResponse.findIndex((existingItem) => existingItem.assetName === assetName && existingItem.zone === zone && existingItem.event === event);
        if (isExist === -1) {
            mappedResponse.push({
                ...alarm,
                dateRanges: [{ event, comment, priority, startOn, endOn }],
            });
        } else {
            mappedResponse[isExist].dateRanges?.push({
                event,
                comment,
                priority,
                startOn,
                endOn,
            });
        }
    }

    const currentDateTime = parsedResponse.data.dataRefreshed ? DateTime.fromISO(parsedResponse.data.dataRefreshed) : DateTime.now();
    const getAlarmTime = (item: AlarmReportItem) => {
        const intervals = generateTimeInterval(currentDateTime, duration);
        const dateTimeIntervals = intervals.map(({ dateTime }) => {
            return dateTime;
        });
        const { dateRanges } = item;
        if (!dateRanges) {
            return undefined;
        }

        const alarm: any = {};
        for (const dateRange of dateRanges) {
            const { startOn, endOn, priority, comment, event } = dateRange;
            const closestDate = findClosestDate(dateTimeIntervals, startOn, endOn);
            const [start] = dateTimeIntervals;
            const targetDateTime = DateTime.fromISO(startOn);
            const isDatetimeBefore = Boolean(targetDateTime <= start);

            const daysOfWeek = getDaysOfWeek();
            if (closestDate) {
                const hours = closestDate.hour;
                const minutes = closestDate.minute;

                let dayOfWeek = closestDate.weekday;
                dayOfWeek = (dayOfWeek + 6) % 7;
                const dayAcronym = daysOfWeek[dayOfWeek];
                const entryKey = `${dayAcronym}_${hours}_${minutes}`;

                if (entryKey in alarm) {
                    alarm[entryKey].dateRanges.push({
                        priority,
                        closestDate,
                        startOn: isDatetimeBefore ? closestDate : startOn,
                        originalStartOn: startOn,
                        endOn,
                        comment,
                        event,
                    });
                } else {
                    alarm[entryKey] = {
                        priority,
                        closestDate,
                        startOn: isDatetimeBefore ? closestDate : startOn,
                        endOn,
                        comment,
                        event,
                        dateRanges: [
                            {
                                priority,
                                closestDate,
                                startOn: isDatetimeBefore ? closestDate : startOn,
                                originalStartOn: startOn,
                                endOn,
                                comment,
                                event,
                            },
                        ],
                    };
                }
            }
        }

        return alarm;
    };

    const alarms = mappedResponse
        .map((item) => {
            const alarm = getAlarmTime(item);
            return { ...item, alarm };
        })
        .filter((item) => item.alarm && Object.keys(item.alarm).length > 0);

    return {
        data: {
            alarms,
            dataRefreshed: parsedResponse.data.dataRefreshed,
        },
    };
};

/**
 * Finds and returns the closest date from a given array of dates to a target date.
 *
 * @returns The closest date to the target date within the provided array, or null if the array is empty or the target date is outside the range of the input dates.
 */
const findClosestDate = (dates: DateTime[], targetDate: string, endOn?: string | null): DateTime | null => {
    if (!dates || dates.length === 0) {
        return null;
    }

    const targetStartDateTime = DateTime.fromISO(targetDate);
    const timeDifferences = dates.map((date) => Math.abs(targetStartDateTime.diff(date).as('milliseconds')));
    const minIndex = timeDifferences.indexOf(Math.min(...timeDifferences));

    const [start] = dates;
    const end = dates[dates.length - 1];

    const interval = Interval.fromDateTimes(start, end);
    const isStartWithinRange = interval.contains(targetStartDateTime);
    let isEndWithinRange = false;
    if (endOn) {
        const targetEndDateTime = DateTime.fromISO(endOn);
        isEndWithinRange = interval.contains(targetEndDateTime);
    }

    // If no end time, means running alarm. Get the start date from given internval instead
    if (targetStartDateTime <= start && !endOn) {
        return dates[0];
    }

    // If start date is less than the given interval and but the end time exist within the interval, get the start date from interval
    if (targetStartDateTime < start && isEndWithinRange) {
        return dates[0];
    }

    if (!isStartWithinRange) {
        return null;
    }

    return dates[minIndex];
};
