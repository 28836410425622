import { Box, makeStyles } from '@material-ui/core';
import { BadRequestErrorWithPaginationSortingFilteringErrorCodes } from 'api/errorCodes/badRequestErrorWithPaginationSortingFilteringErrorCodes';
import { getAlarmReportListView } from 'api/fetchers/alarmReportListView';
import { QueryKeys } from 'api/queryKeys';
import AlarmFilter from 'components/alarmFilter/alarmFilter';
import Content from 'components/content/content';
import ContentHeader from 'components/contentHeader/contentHeader';
import ContentLegend from 'components/contentLegend/contentLegend';
import LoadingBar from 'components/loadingBar/loadingBar';
import Status from 'components/status/status';
import { StatusType } from 'components/status/statusType';
import AlarmListViewTable from 'components/table/instances/alarmListViewTable/alarmListViewTable';
import AlarmListViewTableColumns from 'components/table/instances/alarmListViewTable/alarmListViewTableColumns';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Filters, SortingRule } from 'react-table';
import { formatDateForAPI, transformReactTableFiltersForAPI, transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { AppState } from 'store';
import { alarmReportDataRefreshed, alarmReportFilterListView, alarmReportPriorityListView } from 'store/filters/actions';
import { AlarmTimeDuration } from 'types/alarm/alarmTimeDuration';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            overflowX: 'scroll',
        },
    },
}));

const DEFAULT_INITIAL_TIME_DURATION = parseInt(AlarmTimeDuration.last48);

const AlarmListView: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        duration,
        dataRefreshed,
        listView: { priority, filters },
    } = useSelector((state: AppState) => state.filters.alarmReport);
    const { storeId } = useSelector((state: AppState) => state.store);
    const [sortingRule, setSortingRule] = React.useState<SortingRule<string> | undefined>(undefined);

    const dateFrom = formatDateForAPI(dataRefreshed ? DateTime.fromISO(dataRefreshed).minus({ hours: DEFAULT_INITIAL_TIME_DURATION }) : DateTime.now().minus({ hours: duration }));
    const dateTo = formatDateForAPI(DateTime.now());

    const {
        data: alarmReportData,
        isFetching,
        refetch: refetchAlarmReport,
    } = useHandledQuery(
        [QueryKeys.getAlarmReportListView(), sortingRule, filters, duration, priority, dataRefreshed],
        () => {
            return storeId
                ? getAlarmReportListView({
                      storeId,
                      dateFrom,
                      dateTo,
                      duration,
                      sort: sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined,
                      filter: transformReactTableFiltersForAPI([...(filters ? filters : []), ...(priority && priority !== 0 ? [{ id: 'priority', value: priority }] : [])]),
                  })
                : undefined;
        },
        { refetchInterval: 1000 * 60 * 2, refetchIntervalInBackground: true, keepPreviousData: false, enabled: Boolean(storeId) },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    const handleOnChangeAlarmPriority = (priority: number) => {
        dispatch(alarmReportPriorityListView(priority));
    };

    const handleFilters = (rule: Filters<{}> | undefined) => {
        dispatch(alarmReportFilterListView(rule));
    };

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    useEffect(() => {
        if (alarmReportData?.data.dataRefreshed) {
            dispatch(alarmReportDataRefreshed(alarmReportData.data.dataRefreshed));
        }
    }, [alarmReportData]);

    const columns = AlarmListViewTableColumns({ duration });
    return (
        <Content>
            <div className={classes.root}>
                <LoadingBar isFetching={isFetching} />
                <ContentHeader>
                    <ContentLegend>
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                            <AlarmFilter selectedPriority={priority?.toString()} onChange={handleOnChangeAlarmPriority} />
                            <Status isSquare text={t('alarm.listView.legend.prio1')} type={StatusType.error} />
                            <Status isSquare text={t('alarm.listView.legend.prio2')} type={StatusType.warning} />
                        </Box>
                    </ContentLegend>
                </ContentHeader>
                <AlarmListViewTable
                    columns={columns}
                    data={alarmReportData ? alarmReportData.data.alarms : []}
                    isFetching={false}
                    refetch={refetchAlarmReport}
                    onFilter={handleFilters}
                    onSort={handleSort}
                />
            </div>
        </Content>
    );
};

export default AlarmListView;
